@import '../../../utilities/global';

.footer {
  display: flex;
  align-items: center;

  height: 80px;

  background-color: $main-yellow;
  font-weight: bold;
  color: $blue-gray;
  font-family: $main-font;
  border: $blue-gray solid 10px;

  flex-direction: row;
  justify-content: space-around;

  @include responsive-mobile {
    flex-direction: column;
  }
}

.linkSeparator {
  color: black;
  @include responsive-mobile {
    font-size: 16px;
  }
}

.copyright {
  display: flex;
  align-items: center;
}

.copyright__line-1 {
  display: flex;
  align-items: center;
}

.copyright span {
  text-align: center;
  align-self: center;
  font-family: $content-font;
  color: $blue-gray;
  font-size: 16px;
  @include responsive-mobile {
    font-size: 12px;
  }
}

.copyright span:first-child {
  font-size: 20px;
  @include responsive-mobile {
    font-size: 15px;
  }
}

.social_media__link {
  font-family: $content-font;
  color: $blue-gray;
  font-size: 16px;
  @include responsive-mobile {
    font-size: 16px;
  }
}

.social_media__link:hover {
  text-decoration: underline;
}
