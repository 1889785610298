// Fonts
$name-font: 'Fredericka the Great';
$main-font: 'Montserrat';
$content-font: 'Roboto';
$title-font: 'Josefin Sans';

// Colors
$main-yellow: #ead017;
$main-yellow-darker: #706f38;
$light-gray: #9d8d8f;
$light-brown: #9b786f;
$strong-gray: #5a5560;
$blue-gray: #212136;
$blue-gray-darker: #1a1a2e;
$blue-gray-transparent: #212136f1;
$navigation-timeline-level-0: #bda913;
$navigation-timeline-level-1: #8d7e0e;
$navigation-timeline-level-2: #665b0a;
$navigation-timeline-level-3: #4b4307;
$white: #fff;

// Dimensions
$navbar-height: 62px;
$mobile-styles-applied: 740px;
$tablet-styles-applied: 920px;
$screen-4k-styles-applied: 3000px;
