@import '../../../utilities/global';

.portfolioHighlightSection {
  background-color: $blue-gray-transparent;
  margin-bottom: -1px;
}

.highlight_projectList {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 40px;
  @include responsive-mobile {
    justify-content: flex-start;
    overflow-x: scroll;
  }
}
