@import '../../../utilities/global';

.navbar_container {
  position: fixed;
  margin-top: -61px;
  width: 100vw;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  height: $navbar-height;
  align-items: center;

  background-color: $blue-gray;
}

.menu {
  display: flex;
}
