@import '../../../utilities/global';

.body {
  min-height: calc(100vh - (61px * 2));
  position: relative;
}

.navbarContainer {
  display: block;
  @include responsive-mobile {
    display: none;
  }
}

.navbarMobileContainer {
  display: none;
  @include responsive-mobile {
    display: block;
  }
}

.homePageBackgroundImage {
  // background-image: url('https://s3.us-east-1.amazonaws.com/arnaldocisneros.com/images/medellin-large.jpg') !important;
  background-position: 60% 70%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.pageContainer {
  margin-top: 61px;
  padding-bottom: 20px;
  animation-name: curtainEffect;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}

@keyframes curtainEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.footerContainer {
  position: absolute;
  width: 100vw;
  bottom: -61px;
}
