@import '../../../utilities/global';

.about_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 80px;
  @include responsive-mobile {
    flex-direction: column;
    margin: 40px;
  }
}

.project_description {
  width: 30vw;
  text-align: justify;
  font-family: $content-font;
  font-size: 18px;
  @include responsive-mobile {
    width: 90vw;
    font-size: 16px;
  }
  @include responsive-4k {
    font-size: 26px;
  }
  a {
    text-decoration: underline;
    color: rgb(96, 172, 202);
    transition-property: color;
    transition-duration: 0.1s;
    &:hover {
      color: $main-yellow;
    }
  }
}
