@import '../../../utilities/global';

.aboutPageContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  @include responsive-mobile {
    display: flex;
    flex-direction: column;
  }
  @include responsive-tablet {
    display: flex;
    flex-direction: column;
  }
}

.aboutPageText {
  font-family: $content-font;
  font-size: 20px;
  width: 70%;
  align-self: center;
  justify-self: center;
  text-align: justify;
  margin: 10px 0 10px 0;
  padding: 0 10px 0 10px;
  font-weight: bold;
  @include responsive-mobile {
    font-size: 16px;
    width: 80%;
    text-align: justify;
  }
  @include responsive-4k {
    width: 45%;
    font-size: 26px;
  }
}

.title_aboutMe {
  grid-row: 1/2;
  grid-column: 1/2;
}

.title_aboutWork {
  grid-row: 1/2;
  grid-column: 2/3;
}

.title_aboutExpertise {
  grid-row: 1/2;
  grid-column: 3/4;
}

.title_aboutEducation {
  grid-row: 3/4;
  grid-column: 1/4;
  margin-top: 40px !important;
  @include responsive-mobile {
    margin-top: 10px !important;
  }
}

.text_aboutEducation {
  grid-row: 4/5;
  grid-column: 2/3;
}

.certificateList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0;
  @include responsive-mobile {
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  @include responsive-4k {
    margin: 80px 0;
  }
}

.certificateItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  margin-bottom: 10px;
  @include responsive-mobile {
    min-width: 100vw;
    max-width: 100vw;
  }
}

.courseLink {
  width: 80%;
  text-decoration: none;
  padding: 20px 0px;
  border-radius: 30px;
  border: $blue-gray 2px solid;
  transition-property: border background-color;
  transition-duration: 0.2s;
  &:hover {
    background-color: $blue-gray-darker;
    border: $main-yellow 2px solid;
  }
}

.courseImage {
  max-width: 160px;
  max-height: 160px;
  @include responsive-4k {
    max-width: 300px;
    max-height: 300px;
  }
}

.courseName {
  text-decoration: none;
  font-family: $main-font;
  color: $main-yellow;
  // padding: 0 10px;
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  width: 80%;
  margin: auto;
  @include responsive-mobile {
    font-size: 16px;
    width: 80%;
  }
}

.seeAllCertificates_container {
  margin-top: 40px;
  margin-bottom: 30px;
}

.seeAllCertificates_btn {
  text-align: center;
  align-self: center;
  justify-self: center;
  padding: 3px 10px;
  font-size: 30px !important;
  font-weight: 600 !important;
  @include responsive-mobile {
    font-size: 16px !important;
  }
}
