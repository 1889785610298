@import '../../../utilities/global';

.certificate_cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px auto;
  padding: 2px 10px;
  width: 60%;
  background-color: rgba(128, 128, 128, 0.397);
  border: 3px solid rgba(128, 128, 128, 0);
  border-radius: 10px;
  transition-property: border;
  transition-duration: 0.2s;
  @include responsive-mobile {
    width: 90%;
  }
  &:hover {
    border: 3px solid $main-yellow;
  }
}

.certificate_section_name {
  display: flex;
  align-items: center;
  width: 400px;
}

.certificate_section_tech {
  display: flex;
  align-items: center;
  justify-content: center;
  @include responsive-mobile {
    display: none;
  }
}

.certificate_section_company {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include responsive-mobile {
    display: none;
  }
}

.course_icon {
  width: 50px;
  margin: 0px 30px;
  @include responsive-mobile {
    margin: 0px 10px;
  }
}

.course_tech_icon {
  width: 50px;
}

.academy_icon {
  width: 50px;
}

.course_name {
  margin-left: 10px;
  text-align: start;
}
