@import '../../../utilities/global';

.global__page_title {
  @include responsive-mobile {
    font-size: 16vw !important;
    text-align: center !important;
  }
  @include responsive-4k {
    font-size: 150px !important;
  }
}
