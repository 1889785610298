@import '../../../utilities/global';

.course_cover {
  display: flex;
  justify-content: center;
  margin: 100px;
  @include responsive-mobile {
    flex-direction: column;
    margin: 10px;
  }
}

.course_title {
  font-size: 67px;
  font-family: $main-font;
  color: $main-yellow;
  text-transform: uppercase;
  max-width: 50vw;
  text-align: center;
  margin: auto 0;
  @include responsive-mobile {
    font-size: 8vw;
    max-width: 100vw;
    margin-top: 30px;
  }
  @include responsive-tablet {
    font-size: 45px;
  }
}

.course_linkContainer {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  @include responsive-mobile {
    margin: 30px 0;
  }
}

.certificate_link {
  padding: 20px;
  border-radius: 30px;
  border: $blue-gray 2px solid;
  transition-property: border background-color;
  transition-duration: 0.2s;
  &:hover {
    background-color: $blue-gray-darker;
    border: $main-yellow 2px solid;
  }
}

.course_clickPicture {
  font-family: $main-font;
  font-size: 22px;
  @include responsive-mobile {
    font-size: 16px;
  }
}

.course_generalInfoGrid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 60%;
  margin-bottom: 20px;
  grid-column: 1/3;
  margin: 70px auto;
  @include responsive-mobile {
    width: 80%;
    margin: 20px auto;
    margin-bottom: 50px;
  }
  @include responsive-tablet {
    width: 70%;
  }
  @include responsive-4k {
    width: 30%;
    font-size: 20px;
  }
}

.course_typeColumn {
  padding: 7px 0px;
  width: 100%;
  text-align: left;
  font-weight: 700;
  color: $main-yellow;
  border-bottom: 1px solid $main-yellow;
  font-family: $main-font;
}

.course_contentColumn {
  padding: 7px 0px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  border-bottom: 1px solid $main-yellow;
  font-family: $content-font;
}

.course_tableLink {
  padding: 5px 0;
  width: 100%;
  background-color: $blue-gray-darker;
  border: $blue-gray-darker 2px solid;
  border-radius: 10px;
  transition-property: border color;
  transition-duration: 0.1s;
  &:hover {
    color: $main-yellow;
    border: $main-yellow 2px solid;
    font-weight: 1000;
  }
}

.certificate_badge {
  width: 200px;
  max-width: 300px;
  @include responsive-mobile {
    max-width: 250px;
  }
  @include responsive-tablet {
    max-width: 250px;
  }
  @include responsive-4k {
    width: 400px;
  }
}
