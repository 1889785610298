@import '../../../utilities/global';

.techIconContainer {
  position: relative;
  width: 50px;
  margin: 0 5px;
}

.techTooltip {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;

  font-size: 13px;
  background-color: $blue-gray-darker;
  padding: 5px 10px;
  border: 1px solid white;
  border-radius: 10px;

  width: 120px;
  opacity: 0;
  visibility: hidden;

  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
}

.tooltipVisible_true {
  opacity: 1;
  visibility: visible;
}

.tooltipVisible_false {
  opacity: 0;
  visibility: hidden;
}
