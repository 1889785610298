@import '../../../utilities/global';

.table_row {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $main-yellow;
  width: 400px;
  @include responsive-mobile {
    width: 90vw;
  }
  @include responsive-4k {
    width: 600px;
  }
}

.type-column {
  padding: 7px 0px;
  width: 40%;
  text-align: left;
  font-weight: 700;
  color: $main-yellow;
  font-family: $main-font;
  @include responsive-4k {
    font-size: 20px;
    margin: 10px 0;
  }
}

.content-column {
  padding: 7px 0px;
  width: 60%;
  font-family: $content-font;
  font-weight: 500;
  @include responsive-4k {
    font-size: 20px;
    margin: 10px 0;
  }
}

.general_info_grid {
  @include responsive-mobile {
    margin-top: 80px;
  }
}
