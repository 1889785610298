@import 'responsiveMixins';
@import 'variables';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: white;
}

.global__page_title {
  font-size: 100px;
  text-align: start;
  align-self: center;
  margin: 25px 0px 0px 0px;
  color: $main-yellow;
  font-family: $title-font;
}

.global__page_container {
  width: 90%;
  margin: 0px auto;
}

.global__section_divider {
  align-self: center;
  text-align: center;
  width: 100%;
  padding: 5px 0;
  margin: 10px 0;
  background-color: $main-yellow;
  color: $blue-gray;
  font-family: $main-font;
  font-weight: 400;
}

.global__button {
  font-family: $main-font;
  background-color: $main-yellow;
  border-radius: 5px;
  color: $blue-gray;
  transition-property: background-color;
  transition-duration: 0.2s;
  font-weight: 500;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: $main-yellow-darker;
  }
}

.global__smallButton {
  font-size: 16px;
  font-weight: 500;
  padding: 3px 10px;
  @include responsive-4k {
    font-size: 26px;
    width: 300px;
  }
}

a {
  text-decoration: none;
}
