@import '../../../utilities/global';

.screen_list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px auto;
  width: 60%;
  @include responsive-mobile {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll;
    width: 100% !important;
  }
}

.project_screen {
  display: flex;
  margin: 10px 20px;
  border: 5px solid $blue-gray;
  border-radius: 4px;
  padding: 5px;
  transition-property: border;
  transition-duration: 0.2s;

  &:hover {
    border: 5px solid $main-yellow;
  }

  a {
    width: 200px;
    height: 200px;
    @include responsive-4k {
      width: 400px;
      height: 400px;
    }

    img {
      width: 100%;
      height: 100%;
      align-items: center;
    }
  }
}
