@import '../../../utilities/global';

.projectList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 40px;
  @include responsive-mobile {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll;
    width: 100% !important;
  }
}
