@import '../../../utilities/global';

.project_cover {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
  @include responsive-mobile {
    flex-direction: column;
  }
  h1 {
    font-size: 4vw;
    font-family: $main-font;
    color: $main-yellow;
    text-transform: uppercase;
    max-width: 50vw;
    text-align: center;
    @include responsive-mobile {
      font-size: 8vw;
      max-width: 100vw;
    }
  }
  img {
    max-width: 25vw;
    @include responsive-mobile {
      max-width: 70vw;
      margin: 25px 0px;
    }
  }
}

.ProjectLinks_linkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
