@import '../../../utilities/global';

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 51px);
  background-color: $blue-gray-transparent;
  padding-left: 100px;

  @include responsive-mobile {
    padding-left: 20px;
  }
  @include responsive-tablet {
    padding-left: 40px;
  }
}

.titleContainer {
  font-family: $main-font;
  display: flex;
  flex-direction: column;
  font-weight: bold;

  @include responsive-mobile {
    margin-top: -100px;
  }

  .preTitle {
    text-align: start;
    font-size: 30px;
    font-size: 2vw;
    @include responsive-mobile {
      font-size: 5vw;
    }
    @include responsive-4k {
      font-size: 1.5vw;
    }
  }

  .title {
    text-align: start;
    font-size: 7.8vw;
    color: $main-yellow;
    margin: -10px 0px;
    @include responsive-mobile {
      text-align: start;
      font-size: 14vw;
      margin: 10px 0px;
      margin-left: 20px;
      line-height: 13vw;
    }
    @include responsive-tablet {
      font-size: 9.5vw;
    }
    @include responsive-4k {
      font-size: 5vw;
    }
  }

  .postTitle {
    text-align: start;
    font-size: 30px;
    font-size: 2vw;
    @include responsive-mobile {
      font-size: 4vw;
      margin-left: 20px;
    }
    @include responsive-4k {
      font-size: 1.5vw;
    }
  }
}

.presentationTextContainer {
  font-family: $content-font;
  font-size: 2vw;
  margin-top: 35px;
  padding-right: 40%;
  @include responsive-mobile {
    font-size: 4.5vw;
    padding-right: 40px;
    margin-left: 20px;
  }
  @include responsive-4k {
    font-size: 1vw;
  }

  .presentationText {
    text-align: start;
    @include responsive-mobile {
      text-align: justify;
    }
  }
}

.actionButtonContainer {
  display: flex;
  margin-top: 35px;

  .actionButton {
    align-self: flex-start;
    text-decoration: none;
    font-size: 30px;
    font-weight: 600;
    padding: 5px 10px;
  }
}
