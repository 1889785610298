@import '../../../utilities/global';

@mixin basic_button() {
  border-left: 1px solid $main-yellow;
  padding-left: 20px;
  padding-right: 100px;
  height: $navbar-height;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $white;
  font-family: $main-font;
  font-size: 16px;
}

.button_unselected {
  @include basic_button();
  transition-property: border-left padding-right;
  transition-duration: 0.2s;
  &:hover {
    border-left: 5px solid $main-yellow;
    padding-left: 16px;
  }
}

.button_selected {
  @include basic_button();
  font-weight: bolder;
  border-left: $main-yellow solid 20px;
  padding-right: 80px;
}
