@import '../../../utilities/global';

.projectCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 15px 0px 15px;
  min-width: 20%;
  padding: 20px;
  border-radius: 30px;
  border: $blue-gray 2px solid;
  transition-property: border background-color;
  transition-duration: 0.2s;
  &:hover {
    background-color: $blue-gray-darker;
    border: $main-yellow 2px solid;
  }
  @include responsive-mobile {
    min-width: 100vw;
    margin: 30px 0px 0px 0px;
  }
}

.projectImage {
  height: 300px;
  width: 300px;
  object-fit: cover;
  @include responsive-4k {
    height: 400px;
    width: 400px;
  }
}

.projectName {
  font-family: $main-font;
  color: $main-yellow;
  text-decoration: none;
  margin: 10px 0px;
  font-size: 20px;
  font-weight: bold;
  @include responsive-4k {
    font-size: 26px;
  }
}

.projectDate {
  font-family: $main-font;
  font-style: italic;
  @include responsive-4k {
    font-size: 20px;
  }
}
